import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GiHamburgerMenu } from "react-icons/gi";
import Footer from '../Footer';
import { HashLink as HLink } from 'react-router-hash-link';
import { Container } from 'reactstrap';
import { getMarts } from '../../Server';
const AboutUs = () => {
    const [selectedMartName, setSelectedMartName] = useState('');
    const [Martid, setMartid] = useState(1);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleNavbar = () => setIsCollapsed(!isCollapsed);
    const [martData, setMartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const handleSelectMart = (e, martName, martid) => {
        e.preventDefault();
        setMartid(martid);
        setSelectedMartName(martName);
        setShowDropdown(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            // Getting Mart Data
            try {
                const response = await getMarts();
                console.log('Response Status:', response.status);
                if (response.status === 200) {
                    console.log('Request successful!', response.data);
                    setMartData(response.data);
                    console.log(martData);
                    if (response.data.length > 0) {
                        setSelectedMartName(response.data[0].name);
                    }
                }
                else {
                    console.error('Request failed.', response.data);
                }
            } catch (error) {
                console.error('Error during request:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [Martid]);
    return (
        <div>
            <div className='container'>
                <nav className="navbar-n">
                    <Link to='https://tezzdelivery.com/'><img className='navbar-brand' src='/Images/Logo.png' alt="Logo" /></Link>
                    <div>
                        <button className="navbar-toggler" onClick={toggleNavbar}>
                            <span className="toggler-icon"><GiHamburgerMenu /></span>
                        </button>
                        <div className={`navbar-menu ${isCollapsed ? 'collapsed' : 'expanded display-class'}`}  >
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div className="dropdown1" onClick={() => setShowDropdown(!showDropdown)}>
                                    <button className="dropbtn1">{selectedMartName}<span className="arrow">&#9660;</span></button>
                                    {showDropdown && (
                                        <div className="dropdown-content1">
                                            {martData && martData.map((mart, index) => (
                                                <a key={index} onClick={(e) => handleSelectMart(e, mart.name, mart.inventory_id)}>
                                                    {mart.name}
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <a href="https://onelink.to/3vjjgg" class="download-app img-navbar">
                                        Download App
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className={`navbar-menu ${isCollapsed ? 'collapsed' : 'expanded'}`} >
                    <div className="additional-links">
                        <div className='expanded-container'>
                            <a target='_blank' href={`http://marts.tezzdelivery.com/home?martId=${Martid}`} className="anchor-style">
                                <h6 style={{ fontSize: '15px' }}>All Categories</h6>
                            </a>
                            <HLink smooth to='/#who-we-are' className='anchor-style'><h6 style={{ fontSize: '15px' }} >Who We Are</h6></HLink>
                            <HLink smooth to='/#deals-of-the-week' className='anchor-style'><h6 style={{ fontSize: '15px' }}>Deals of the Week</h6></HLink>
                            <HLink smooth to='/#why-tezz-exists' className='anchor-style'><h6 style={{ fontSize: '15px' }}>Why Tezz Exists</h6></HLink>
                            <a href='/about-us' className='anchor-style'><h6 style={{ fontSize: '15px' }}>About Us</h6></a>
                        </div>
                    </div>
                </div>
                <section className='section1'>
                    <div className='mob-dropdown'>
                        <div className="dropdown" onClick={() => setShowDropdown(!showDropdown)}>
                            <button className="dropbtn">{selectedMartName}<span className="arrow">&#9660;</span></button>
                            {showDropdown && (
                                <div className="dropdown-content">
                                    {martData && martData.map((mart, index) => (
                                        <a key={index} href="#" onClick={(e) => handleSelectMart(e, mart.name, mart.inventory_id)}>
                                            {mart.name}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
            <Container style={{ backgroundColor: '#4D5882' }} fluid >
                <section className='container'>
                    <div className='con-comp'>
                        <a target='_blank' href={`http://marts.tezzdelivery.com/home?martId=${Martid}`} className="anchor-style">
                            <h6 style={{ color: '#fafafa' }}>All Categories</h6>
                        </a>
                        <HLink smooth to='/#who-we-are' className='anchor-style'><h6 style={{color:'#fafafa' }} >Who We Are</h6></HLink>
                        <HLink smooth to='/#deals-of-the-week' className='anchor-style'><h6 style={{ color: '#fafafa' }}>Deals of the Week</h6></HLink>
                        <HLink smooth to='/#why-tezz-exists' className='anchor-style'><h6 style={{ color: '#fafafa' }}>Why Tezz Exists</h6></HLink>
                        <Link to='/about-us' className='anchor-style'><h6 style={{ color: '#fafafa' }}>About Us</h6></Link>
                    </div>
                </section>
            </Container>
            <Container fluid className='ChooseUs-SectionImg'>
                <section className="container " style={{ padding: '2rem 0' }} id='who-we-are'>
                    <div className='chooseusImg'>
                        <div class="choose-us-heading">
                            <h2 className='main_heading'>About Us</h2>
                        </div>
                        <div class="about-us-tagline ">
                            <p className='about-us-text'>
                                Tezz Delivery is a one-step solution for your daily shopping needs.
                            </p>
                            <p className='about-us-text'>Frustrated with disruptive deliveries  and unrelieble delivery times?We came up with a solution
                                that keeps tha quintessential modern consumer in mind.You can now order any grocery item,from pharmaceuticals to cosmetics,toiletries,everyday essential,fruits and vegetables,
                                and much much more,and it will delivered to your location of choice in Islamabad, DHA and Baharia in less than 2 hours, with FREE DELIVERY on ALL ORDERS.</p>
                            <p className='about-us-text'>Whether you are a bussiness looking for a grocery delivery solution for your offices or schools , or a customer with their
                                fingers crossed for a smooth delivery experiencem you have come  to the right place!
                            </p>
                        </div>
                    </div>
                </section>
            </Container>
            {/* Why you Choose Us (images section ) */}
            {/* <Container fluid className='ChooseUs-SectionImg'>
                <section className="container " style={{ padding: '0 0' }} id='who-we-are'>
                    <div className='chooseusImg'>
                        <div class="choose-us-heading">
                            <h2 className='main_heading'>Who We Are?</h2>
                        </div>
                        <div class="choose-us-tagline pb">
                            <p style={{ color: '#7c8081' }}>Tezz delivery is your one stop shop whether you are a housewife, a chef, run your own business, or need corporate orders, we are here for you!</p>
                        </div>
                        <div className="features">
                            <div className="feature-item">
                                <img src="/Images/freeDelivery.jpeg" alt="" />
                                <h6>Free Shipping</h6>
                                <p>We provide free delivery in LESS than 2 hours within our delivery area. (please call us for out of service area deliveries).  Get Ordering Now!</p>
                            </div>
                            <div className="feature-item">
                                <img src="/Images/save.jpeg" alt="" />
                                <h6>Simplify your Life </h6>
                                <p>We pick up your goods from our partner marts and always charge instore prices. </p>
                            </div>
                            <div className="feature-item">
                                <img src="/Images/FreshVeges.jpeg" alt="" />
                                <h6>Competitive Prices</h6>
                                <p>Never visit a store again because we provide all your favourite brands, at store prices and with free delivery, all of this with Instant Delivery. </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Container> */}
            {/* Why you Choose Us (images section ) */}
            <Container fluid className='ChooseUs-SectionImg'>
                <section className="container " style={{ padding: '2rem 0' }} id='who-we-are'>
                    <div className='chooseusImg'>
                        <div class="choose-us-heading">
                            <h2 className='main_heading'>Who We Are?</h2>
                        </div>
                        <div class="choose-us-tagline pb">
                            <p style={{ color: '#7c8081' }}>Tezz delivery is your one stop shop whether you are a housewife, a chef, run your own business, or need corporate orders, we are here for you!</p>
                        </div>
                        <div className="features">
                            <div className="feature-item">
                                <img src="/Images/freeDelivery.jpeg" alt="" />
                                <h6>Free Shipping</h6>
                                <p>We provide free delivery in LESS than 2 hours within our delivery area. (please call us for out of service area deliveries).  Get Ordering Now!</p>
                            </div>
                            <div className="feature-item">
                                <img src="/Images/save.jpeg" alt="" />
                                <h6>Simplify your Life </h6>
                                <p>We pick up your goods from our partner marts and always charge instore prices. </p>
                            </div>
                            <div className="feature-item">
                                <img src="/Images/FreshVeges.jpeg" alt="" />
                                <h6>Competitive Prices</h6>
                                <p>Never visit a store again because we provide all your favourite brands, at store prices and with free delivery, all of this with Instant Delivery. </p>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            {/* Download app */}
            <Container style={{ backgroundColor: '#858ca9' }} fluid >
                <section className='container' id="download-app-section">
                    <div className='download-comp'>
                        <h4>For Better Experience Download the App Now !</h4>
                        <div className='download-btns'>
                            <a href='https://apps.apple.com/eg/app/tezz-delivery/id1632938996'><img src='/Images/Appstore.png' alt='' className="img" /></a>
                            <a href='https://play.google.com/store/apps/details?id=app.grocery.tezz'><img src='/Images/Playstore.png' alt='' className="img" /></a>
                        </div>
                    </div>
                </section>
            </Container>
            {/* Why you Choose Us */}
            <Container fluid className='ChooseUs-Section'>
                <section className="container " style={{ padding: '2rem 0' }} id='why-tezz-exists'>
                    <div className='chooseus'>
                        <div class="choose-us-heading">
                            <h2 className='main_heading'>Why Tezz Exists?</h2>
                        </div>
                        <div class="choose-us-tagline pb">
                            <p style={{ color: '#7c8081' }}>
                                Tezz is more than just a transactional app.<br />
                                Help us build a community in which three types of folks matter most.
                            </p>
                        </div>
                        <div className="features">

                            <div className="feature-item">
                                <img src="/Images/TDimg1.jpeg" alt="" />
                                <h6>Employees</h6>
                                <p>TD is an equal opportunity employer that encourages staff to upskill and elevate at work and society.</p>
                            </div>
                            <div className="feature-item">
                                <img src="/Images/TDimg2.jpeg" alt="" />
                                <h6>Underprivileged members of our community</h6>
                                <p>We actively provide rations and cooked food to the underprivileged members of our society, as well as widows and orphans development organisations.</p>
                            </div>
                            <div className="feature-item">
                                <img src="/Images/TDimg3.jpeg" alt="" />
                                <h6>Customers</h6>
                                <p>The happier you are, the more you order, the more we are able to give to the community</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            <Footer />
        </div>
    )
}

export default AboutUs
