import React from 'react'
import { Row, Container } from 'reactstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BsFacebook } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { FaApple } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { TiSocialFacebook } from "react-icons/ti";
import './Footer.css';
const Footer = () => {
  return (
    <>
      <Container style={{ backgroundColor: '#4D5882' }} fluid>
        <section className='container'>
          <div className='row'>
            <div className='footer-col itemStyle' >
              <h6 className='text-left' style={{cursor:'pointer'}} onClick={() => window.location.href = 'tel:03334878399'}>Call Us Now</h6>
              <div className='divStyle'>
                <div className='icon-style'>
                  <FiPhoneCall />
                </div>
                <h6 className='text-left' style={{cursor:'pointer'}} onClick={() => window.location.href = 'tel:03334878399'}>(0333) 487 8399</h6>
              </div>
              <div style={{ marginTop: '7px' }}>
              <span  >17, 1st floor, capital trade center    </span><br />
              <span>    F10 Markaz,Islamabad</span>
              </div>
            </div>
            <div className='footer-col itemStyle'>
              <h6 className='text-left'>
                <Link to='/about-us' className="div-links">About Us</Link>
              </h6>
              <h6 className='text-left' style={{ marginTop: '7px' }}><a href='/privacy-policy' className="div-links">Privacy Policy</a></h6>
              <h6 className='text-left div-links' ><a  style={{color:'white',whiteSpace:'nowrap',display:'inline-block'}} href='https://www.google.com/search?q=tezz+delivery&oq=tezz+delivery&gs_lcrp=EgZjaHJvbWUyCQgAEEUYORiABDIGCAEQRRg8MgYIAhBFGDwyBggDEEUYPNIBCDQxMjJqMGo0qAIOsAIB&client=ms-android-att-us-revc&sourceid=chrome-mobile&ie=UTF-8#lkt=LocalPoiReviews&trex=m_t:lcl_akp,rc_f:rln,rc_ludocids:5869703539148046097,ru_gwp:0%252C7,ru_lqi:Cg10ZXp6IGRlbGl2ZXJ5kgESZV9jb21tZXJjZV9zZXJ2aWNl,trex_id:Zc6whd'><h6 className='text-left' style={{cursor:'pointer',textDecoration:'underline',marginTop:'10px'}} >Give Us Feedback</h6></a></h6>
              <div className='icons-div'>
                <div className='icons-circle'>
                  <a href='https://www.facebook.com/tezzdeliverypk' style={{display:'flex'}}><TiSocialFacebook className=" icons" size={12} color='#15489D'/></a>
                </div>
                <div className='icons-circle'>
                  <a href='https://www.instagram.com/tezzdelivery/' style={{display:'flex'}}><IoLogoInstagram className=" icons  display-5 " color='#15489D' /></a>
                </div>
              </div>
            </div>
            <div className='footer-col itemStyle apps third-div ml'>
              <h6 className='download-mobile-apps text-left'>Download Mobile Apps</h6>
              <div className='play-div'>
                <div className='icons-circle'>
                  <a href='https://apps.apple.com/eg/app/tezz-delivery/id1632938996' style={{display:'flex'}}><FaApple className=" icons" color='black' size={12} /></a>
                </div>
                <div className='icons-circle '>
                 <a href='https://play.google.com/store/apps/details?id=app.grocery.tezz' style={{display:'flex'}}><img src='/Images/google-play.png' alt="" className=" icons" size={12} /></a> 
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
      <Container style={{ backgroundColor: '#434F7B' }} fluid>
      <span className='copyright'>Copyright 2024 &copy; Tezz Delivery. All rights reserved</span>
      </Container>
    </>
  )
}

export default Footer
