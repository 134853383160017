import { useState,useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Components/LandingPageFiles/LandingPage";
import Loader from "./Components/Loader";
import "./App.css";
import { Privacy_Policy } from "./Components/LandingPageFiles/Privacy_Policy";
import AboutUs from "./Components/LandingPageFiles/AboutUs";

function App() {
  const [loading,setLoading]=useState(true);
  useEffect(()=>{
    //setLoading(true);
    setTimeout(()=>{
      setLoading(false);
    },1000)
  },[])
  return (
      <div className="App">
      {loading ? (
        <Loader />
         ) : ( 
          <>
         <BrowserRouter>
          <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/about-us" element={<AboutUs />} />
          </Routes>
        </BrowserRouter> 
        </>
       )}
    </div>
  );
}
export default App;
